<template>
      <div class="current1" >
             <h1>需要开通权限</h1>
      </div>
</template>

<script>
    export default {
      
    }
</script>

<style scoped>

.current1{
    position: relative;
    width: 98%;
    height: 98%;
    overflow: auto;
    margin: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error{
      width:100%;
      height: 100%;
  }
</style>
